// App.js

import { View } from "react-native";
import React, { useState, useEffect } from "react";
import { fetchFormData } from "./helpers/api";
import FormView from "./components/Forms/FormView";
import TopNavigation from "./components/Layout/TopNavigation";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "./styles.css";

const firebaseConfig = {
  apiKey: "AIzaSyB9kVuBE2JaW-fDPILGEXSJSnzm_vBl1ps",
  authDomain: "chromatic-hue-384900.firebaseapp.com",
  projectId: "chromatic-hue-384900",
  storageBucket: "chromatic-hue-384900.appspot.com",
  messagingSenderId: "446138201833",
  appId: "1:446138201833:web:5c0a6587382c7e8fbca278",
  measurementId: "G-8MYB2E7VBG"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default function App() {
  const [data, setData] = useState({});
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const formId = "8e2c42ab-0cbc-4b43-9eee-71a6bad7bce4";

  useEffect(() => {
    // Make a GET API call when the component mounts
    const fetchData = async () => {
      try {
        await fetchFormData(formId, setData, setGroups, setSelectedGroup);
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run once on mount

  useEffect(() => {
    // Load the selected group from localStorage when groups change
    const getSelectedGroup = () => {
      try {
        const storedGroup = localStorage.getItem("selectedGroupForPageState");
        if (storedGroup && groups.includes(storedGroup)) {
          setSelectedGroup(storedGroup);
        }
      } catch (error) {
        console.error("Error loading selected group from localStorage:", error);
      }
    };

    getSelectedGroup();
  }, [groups]);

  const handleGroupSelect = (group) => {
    // Update the selected group state
    setSelectedGroup(group);

    // Save the selected group to localStorage
    try {
      localStorage.setItem("selectedGroupForPageState", group);
    } catch (error) {
      console.error("Error saving selected group to localStorage:", error);
    }
  };

  return (
    <View className="flex-1">
      <TopNavigation title={data.originalname} />
      <FormView
        data={data}
        formId={formId}
        selectedGroup={selectedGroup}
        groups={groups}
        handleGroupSelect={handleGroupSelect}
      />
    </View>
  );
}
