// GroupView.js
import React, { useState, useEffect } from "react";
import { ScrollView, View } from "react-native";
import PropTypes from "prop-types";
import FieldView from "../Fields/FieldView";

import "../../styles.css";

function GroupView({ fileInfo, groupName, setInvalidFields }) {
  const fieldList = fileInfo.fields;

  const fieldsByGroup = {};

  // Group fields by their group name
  for (const fieldName in fieldList) {
    const field = fieldList[fieldName];
    const group = field.group ? field.group.name : "Uncategorized";

    if (!fieldsByGroup[group]) {
      fieldsByGroup[group] = [];
    }

    fieldsByGroup[group].push({
      ...field, // Pass the entire field information
      name: fieldName,
    });
  }

  // Render the specified group with its fields
  const fieldsToRender = fieldsByGroup[groupName] || [];
  const [invalidFields, setLocalInvalidFields] = useState([]);

  useEffect(() => {
    // Pass information about invalid fields back to the FilePage component
    setInvalidFields(invalidFields);
  }, [invalidFields, setInvalidFields]);

  const handleFieldValidation = (fieldName, isValid) => {
    // Update local invalid fields state
    setLocalInvalidFields((prevInvalidFields) =>
      isValid
        ? prevInvalidFields.filter((field) => field !== fieldName)
        : [...prevInvalidFields, fieldName],
    );
  };

  return (
    <ScrollView style={{ height: 400 }}>
      {/* Set the desired height */}
      <View>
        {fieldsToRender.map((field, fieldIndex) => (
          <FieldView
            key={fieldIndex}
            field={field}
            onFieldValidation={handleFieldValidation}
          />
        ))}
      </View>
    </ScrollView>
  );
}

GroupView.propTypes = {
  fileInfo: PropTypes.shape({
    fields: PropTypes.objectOf(
      PropTypes.shape({
        group: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        displayName: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
  }),
  groupName: PropTypes.string.isRequired,
  setInvalidFields: PropTypes.func.isRequired,
};

export default GroupView;
