// FormView.js
import React, { useState } from "react";
import { Text, View, Pressable, Modal } from "react-native";
import GroupView from "./GroupView";
import FormGroupNavigator from "./FormGroupNavigator";
import PropTypes from "prop-types";
import { fillForm } from "../../helpers/api";
import { validateInput } from "../../helpers/FieldValidation";

import "../../styles.css";

// Add these imports for pop-in notification
import PopInNotification from "../Layout/PopInNotification"; // Create this component

const FormView = ({
  data,
  formId,
  selectedGroup,
  groups,
  handleGroupSelect,
}) => {
  const [invalidFields, setInvalidFields] = useState([]);
  const [popInVisible, setPopInVisible] = useState(false); // State for pop-in visibility
  const [popInMessage, setPopInMessage] = useState(""); // State for pop-in message

  const getFieldValidationForField = (fieldName) => {
    return data.fields[fieldName]?.fieldValidation || {};
  };

  const handleFormSubmit = async () => {
    const formData = {};
    let hasInvalidFields = false;
    const remainingInvalidFields = [];

    // Validate all fields
    for (const key in data.fields) {
      // Get the value from localStorage or treat it as an empty string if not present
      const value = localStorage.getItem(key);

      const fieldValidation = getFieldValidationForField(key);

      const { isValid } = validateInput(value || "", fieldValidation);

      if (!isValid) {
        setInvalidFields((prevInvalidFields) => [...prevInvalidFields, key]);
        console.log(invalidFields);
        hasInvalidFields = true;
        remainingInvalidFields.push(key);
      }
    }

    // Display error and mark invalid fields using pop-in
    if (hasInvalidFields) {
      // Show a pop-in message
      setPopInMessage(
        `Please fill in all required fields correctly before submitting.\nRemaining invalid fields:\n${remainingInvalidFields}`,
      );
      setPopInVisible(true);
      return;
    }

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      formData[key] = value;
    }
    try {
      await fillForm(formId, formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <View className="flex-row flex-1">
      <FormGroupNavigator
        groups={groups}
        handleGroupSelect={handleGroupSelect}
        selectedGroup={selectedGroup}
      />
      <View className="flex-1 p-4 bg-gray-100">
        {selectedGroup ? (
          <GroupView
            fileInfo={data}
            groupName={selectedGroup}
            setInvalidFields={setInvalidFields}
          />
        ) : (
          <Text className="text-lg font-bold">
            Select a group from the side navigation
          </Text>
        )}

        {/* Add Submit Button */}
        <View className="flex flex-col items-end">
          <Pressable title="Submit" onPress={handleFormSubmit}>
            <View className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2">
              <Text>Submit</Text>
            </View>
          </Pressable>
        </View>

        {/* Add PopInNotification */}
        <Modal animationType="slide" transparent={true} visible={popInVisible}>
          <PopInNotification
            message={popInMessage}
            onClose={() => setPopInVisible(false)}
          />
        </Modal>
      </View>
    </View>
  );
};

// Prop-type validation
FormView.propTypes = {
  data: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  selectedGroup: PropTypes.string,
  groups: PropTypes.array.isRequired,
  handleGroupSelect: PropTypes.func.isRequired,
};

export default FormView;
