import React from "react";
import { Text } from "react-native";
import PropTypes from "prop-types";

import "../../styles.css";

function FieldInstruction({ instructions }) {
  if (!instructions || !instructions.type) {
    return null;
  }

  switch (instructions.type) {
    case "text":
      return (
        <Text className="text-base text-gray-500 mb-4">
          {instructions.value}
        </Text>
      );
    // Add more cases as needed for other instruction types
    default:
      return null;
  }
}

FieldInstruction.propTypes = {
  instructions: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default FieldInstruction;
