// TopNavigation.js

import { Text, View } from "react-native";
import React from "react";
import PropTypes from "prop-types";

import "../../styles.css";

const TopNavigation = ({ title }) => {
  return (
    <View className="bg-blue-500 p-4">
      <Text className="text-white text-2xl font-bold">
        {title || "Select a File to view its stuff"}
      </Text>
    </View>
  );
};

// Prop-type validation
TopNavigation.propTypes = {
  title: PropTypes.string,
};

export default TopNavigation;
