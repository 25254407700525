// RenderInputComponent.js
import React from "react";
import { View, Text, TextInput, CheckBox, Picker } from "react-native";
import PropTypes from "prop-types";

const FieldInput = ({
  type,
  textValue,
  isChecked,
  selectedDropdownValue,
  handleTextInputChange,
  handleCheckBoxChange,
  handleDropdownChange,
  isValid,
  validationMessage,
  fieldName,
}) => {
  return (
    <View>
      {type === "PDFTextField" && (
        <View>
          <TextInput
            className={`border border-gray-300 p-2 mb-4 ${
              isValid ? "" : "border-red-500"
            }`}
            value={textValue}
            onChangeText={handleTextInputChange}
            id={fieldName}
          />
          {!isValid && (
            <Text className="text-red-500">{validationMessage}</Text>
          )}
        </View>
      )}

      {type === "PDFCheckBox" && (
        <View>
          <CheckBox
            value={isChecked}
            onValueChange={handleCheckBoxChange}
            className="mb-4"
            id={fieldName}
          />
          {!isValid && (
            <Text className="text-red-500">{validationMessage}</Text>
          )}
        </View>
      )}

      {type === "PDFDropdown" && (
        <View>
          <Picker
            className={`border border-gray-300 mb-4 ${
              isValid ? "" : "border-red-500"
            }`}
            selectedValue={selectedDropdownValue}
            onValueChange={handleDropdownChange}
            id={fieldName}
          >
            <Picker.Item label="Select an Option" value="" />
            <Picker.Item label="Option 1" value="option1" />
            <Picker.Item label="Option 2" value="option2" />
            <Picker.Item label="Option 3" value="option3" />
            <Picker.Item label="Option 4" value="option4" />
          </Picker>
          {!isValid && (
            <Text className="text-red-500">{validationMessage}</Text>
          )}
        </View>
      )}
    </View>
  );
};

FieldInput.propTypes = {
  type: PropTypes.string.isRequired,
  textValue: PropTypes.string,
  isChecked: PropTypes.bool,
  selectedDropdownValue: PropTypes.string,
  handleTextInputChange: PropTypes.func,
  handleCheckBoxChange: PropTypes.func,
  handleDropdownChange: PropTypes.func,
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  fieldName: PropTypes.string,
};

export default FieldInput;
