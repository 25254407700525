const config = {
  apiUrl: "https://api-sandbox-77317.firebaseapp.com"
};



// if (process.env.NODE_ENV === "production") {
//   config.apiUrl = process.env.API_URL;
// } else {
//   config.apiUrl = process.env.API_URL || "http://localhost:3000";
// }

export default config;
