// FieldValidation.js
export const validateInput = (value, fieldValidation) => {
  // Reset validation state
  let isValid = true;
  let validationMessage = "";

  if (!fieldValidation) {
    return { isValid, validationMessage };
  }

  if (Object.keys(fieldValidation).length > 0) {
    console.log(
      `Validating field with value ${value} against ${JSON.stringify(
        fieldValidation,
      )}`,
    );

    const { required, minLength, maxLength, minValue, maxValue, pattern } =
      fieldValidation;

    // Check for required field
    if (required && !value.trim()) {
      isValid = false;
      validationMessage = "This field is required.";
    }

    // Check for minimum value
    if (minValue !== undefined && value.length < minValue) {
      isValid = false;
      validationMessage = `Minimum value is ${minValue}.`;
    }

    // Check for maximum value
    if (maxValue !== undefined && value.length > maxValue) {
      isValid = false;
      validationMessage = `Maximum value is ${maxValue}.`;
    }

    // Check for minimum length
    if (minLength !== undefined && value.length < minLength) {
      isValid = false;
      validationMessage = `Minimum length is ${minLength} characters.`;
    }

    // Check for maximum length
    if (maxLength !== undefined && value.length > maxLength) {
      isValid = false;
      validationMessage = `Maximum length is ${maxLength} characters.`;
    }

    // Check against pattern
    if (pattern instanceof RegExp && !pattern.test(value)) {
      isValid = false;
      validationMessage = "Invalid input pattern.";
    }
  }

  return { isValid, validationMessage };
};

export const saveTolocalStorage = (displayName, value) => {
  localStorage.setItem(displayName, value);
};
