// FormGroupNavigator.js

import { Text, View, Pressable } from "react-native";
import React from "react";
import PropTypes from "prop-types";

import "../../styles.css";

const FormGroupNavigator = ({ groups, handleGroupSelect, selectedGroup }) => {
  return (
    <View className="w-1/8 bg-lightgray">
      {groups.map((group, index) => (
        <Pressable
          key={index}
          onPress={() => handleGroupSelect(group)}
          className={`p-4 bg-white border-b border-gray-300 transition transform  hover:bg-blue-400 ${
            selectedGroup === group ? "bg-blue-400" : ""
          }`}
        >
          <Text className="text-base">{group}</Text>
        </Pressable>
      ))}
    </View>
  );
};

// Prop-type validation
FormGroupNavigator.propTypes = {
  groups: PropTypes.array.isRequired,
  handleGroupSelect: PropTypes.func.isRequired,
};

export default FormGroupNavigator;
