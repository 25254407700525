// PopInNotification.js
import React from "react";
import { View, Text, Pressable } from "react-native";

const PopInNotification = ({ message, onClose }) => {
  return (
    <View className="flex-1 justify-center items-center">
      <View className="bg-white p-4 rounded-lg shadow-md">
        <Text className="mb-5 text-lg font-normal text-gray-500">
          {message}
        </Text>
        <Pressable
          onPress={onClose}
          className="text-white bg-red-600 hover:bg-red-800 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
        >
          <Text className="text-white">Close</Text>
        </Pressable>
      </View>
    </View>
  );
};

export default PopInNotification;
