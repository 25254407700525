import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import PropTypes from "prop-types";
import FieldInstruction from "./FieldInstruction";
import FieldInput from "./FieldInput";
import {
  validateInput,
  saveTolocalStorage,
} from "../../helpers/FieldValidation";
import "../../styles.css";

function FieldView({ field, onFieldValidation }) {
  const { type, displayName, name, fieldValidation } = field;

  const [isChecked, setIsChecked] = useState(
    type === "PDFCheckBox" && localStorage.getItem(name) === "true",
  );
  const [textValue, setTextValue] = useState(
    type === "PDFTextField" ? localStorage.getItem(name) || "" : "",
  );
  const [selectedDropdownValue, setSelectedDropdownValue] = useState(
    type === "PDFDropdown" ? localStorage.getItem(name) || "" : "",
  );

  const [isValid, setIsValid] = useState(true);
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    setIsChecked(
      type === "PDFCheckBox" && localStorage.getItem(name) === "true",
    );
    setTextValue(
      type === "PDFTextField" ? localStorage.getItem(name) || "" : "",
    );
    setSelectedDropdownValue(
      type === "PDFDropdown" ? localStorage.getItem(name) || "" : "",
    );
    setIsValid(true);
    setValidationMessage("");
  }, [name, type]);

  // State variables to track whether initial validation is done for each field
  const [textValueValidationDone, setTextValueValidationDone] = useState(false);
  const [dropdownValueValidationDone, setDropdownValueValidationDone] =
    useState(false);

  useEffect(() => {
    // Run initial validation for textValue only if it hasn't been done
    if (!textValueValidationDone && textValue !== "") {
      validateField(textValue);
      setTextValueValidationDone(true);
    }
  }, [textValue, textValueValidationDone]);

  useEffect(() => {
    // Run initial validation for selectedDropdownValue only if it hasn't been done
    if (!dropdownValueValidationDone && selectedDropdownValue !== "") {
      validateField(selectedDropdownValue);
      setDropdownValueValidationDone(true);
    }
  }, [selectedDropdownValue, dropdownValueValidationDone]);

  const handleCheckBoxChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    saveTolocalStorage(name, newValue);
    validateField(newValue);
  };

  const handleTextInputChange = (value) => {
    setTextValue(value);
    saveTolocalStorage(name, value);
    validateField(value);
  };

  const handleDropdownChange = (itemValue) => {
    setSelectedDropdownValue(itemValue);
    saveTolocalStorage(name, itemValue);
    validateField(itemValue);
  };

  const validateField = (value) => {
    const inputValue =
      value !== undefined
        ? value
        : type === "PDFCheckBox"
          ? isChecked
          : type === "PDFTextField"
            ? textValue
            : selectedDropdownValue;

    const { isValid, validationMessage } = validateInput(
      inputValue,
      fieldValidation,
    );
    setIsValid(isValid);
    setValidationMessage(validationMessage || "");

    onFieldValidation(name, isValid);
  };

  return (
    <View>
      <Text
        className={`text-lg font-bold mb-2 ${isValid ? "" : "text-red-500"}`}
      >
        {displayName}
      </Text>
      <FieldInstruction instructions={field.instructions} />
      <FieldInput
        type={type}
        textValue={textValue}
        isChecked={isChecked}
        fieldName={displayName}
        selectedDropdownValue={selectedDropdownValue}
        handleTextInputChange={handleTextInputChange}
        handleCheckBoxChange={handleCheckBoxChange}
        handleDropdownChange={handleDropdownChange}
        isValid={isValid}
        validationMessage={validationMessage}
      />
    </View>
  );
}

FieldView.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    instructions: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
    }),
    fieldValidation: PropTypes.shape({
      required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      minLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      pattern: PropTypes.oneOfType([
        PropTypes.instanceOf(RegExp),
        PropTypes.string,
      ]),
    }),
  }).isRequired,
  onFieldValidation: PropTypes.func.isRequired,
};

export default FieldView;
