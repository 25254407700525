// api.js
import config from "../config.js";

export const fetchFormData = async (
  formId,
  setData,
  setGroups,
  setSelectedGroup,
) => {
  try {
    const response = await fetch(`${config.apiUrl}/files/${formId}`);
    const responseData = await response.json();

    setData(responseData);

    const groups = Array.from(
      new Set(
        Object.values(responseData.fields).map((field) =>
          field.group !== undefined && Object.keys(field.group).length > 0
            ? field.group.name
            : "etc",
        ),
      ),
    );
    setGroups(groups);

    const selectedGroup = groups[0];
    setSelectedGroup(selectedGroup);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const fillForm = async (formId, formData) => {
  try {
    const response = await fetch(`${config.apiUrl}/files/fill/${formId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set the content type according to your API requirements
      },
      body: JSON.stringify(formData), // Convert your form data to JSON format
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fill form. Server returned ${response.status}`,
      );
    }

    // Assuming the server responds with a PDF file, you can handle it accordingly
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    window.open(url); // Open the PDF in a new tab or handle it as needed
  } catch (error) {
    console.error("Error filling form:", error);
  }
};
